import React, { useEffect, useState, useCallback } from 'react';
import { __ } from '@wordpress/i18n';
import { pathOr } from 'ramda';
import NumberFlow from '@number-flow/react';

// store
import { useStore } from '../../store';

// components
import PreInstructorSoccorsiTable from './components/PreInstructorSoccorsiTable';
import DashboardService from '../../service/dashboard-service';

const SoccorsoIstruttorioPreInstructor = () => {
    const [mainStats, setMainStats] = useState({});
    const userData = useStore().main.userData()

    const getStats = (data) => {
        if (data.status === 'SUCCESS') {
            setMainStats(data.data);
        }
    }

    const errGetStats = () => {}

    const getStatValue = useCallback((key, fallback = '') => {
        return pathOr(fallback, [key], mainStats);
    }, [mainStats]);

    useEffect(() => {
        DashboardService.getAmendmentsStats(getStats, errGetStats);
    }, []);

    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Soccorso istruttorio', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection statsBigBadges">
                <h2>{__('Riepilogo', 'gepafin')}</h2>
                <div className="statsBigBadges__grid applStats">
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Totale richieste', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('totalAmendments', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('In attesa risposta', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('waitingForResponseAmendments', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Risposte ricevute', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('responseReceivedAmendments', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Tempo medio di risposta', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('averageResponseDays', 0)}
                            format={{ notation: 'compact' }}
                            suffix={` ${__('giorni', 'gepafin')}`}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Scadute', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('expiredAmendments', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Richieste in scadenza (48h)', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('expiringRequestsIn48Hours', 0)}
                            format={{ notation: 'compact' }}
                            locales="en-US"/></span>
                    </div>
                </div>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <PreInstructorSoccorsiTable userId={userData.id}/>
            </div>
        </div>
    )
}

export default SoccorsoIstruttorioPreInstructor;