import React, { useEffect, useState, useCallback } from 'react';
import { __ } from '@wordpress/i18n';
import { isEmpty, pathOr } from 'ramda';
import NumberFlow from '@number-flow/react';

// store
import { useStore } from '../../store';

// components
import DashboardService from '../../service/dashboard-service';
import ChartDomandePerStato from '../../components/ChartDomandePerStato';
import ChartRichiesteVsApprovate from '../../components/ChartRichiesteVsApprovate';
import BeneficiarioUltimeDomandeTable from './components/BeneficiarioUltimeDomandeTable';

const StatsBeneficiary = () => {
    const [mainStats, setMainStats] = useState({});
    const [chartStats, setChartStats] = useState({});
    const chosenCompanyId = useStore().main.chosenCompanyId();

    const getStats = (resp) => {
        if (resp.status === 'SUCCESS') {
            setMainStats(resp.data.applicationWidget);
            setChartStats(resp.data.applicationWidgetBars);
        }
    }

    const errGetStats = () => {}

    const getStatValue = useCallback((key, fallback = '') => {
        return pathOr(fallback, [key], mainStats);
    }, [mainStats]);

    useEffect(() => {
        DashboardService.getBeneficiaryStatsPage(chosenCompanyId, getStats, errGetStats);
    }, [chosenCompanyId]);

    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Statistiche', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection statsBigBadges">
                <h2>{__('Riepilogo', 'gepafin')}</h2>
                <div className="statsBigBadges__grid doubleStatsItems">
                    <div className="statsBigBadges__gridItemDoubleStatsBeneficiary">
                        <span>{__('Domande presentate', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('submittedApplication', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItemDoubleStatsBeneficiary">
                        <span>{__('Tasso di successo', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('waitingForResponseAmendments', 0)}
                            format={{ notation: 'compact' }}
                            suffix={` ${__('%', 'gepafin')}`}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItemDoubleStatsBeneficiary">
                        <span>{__('Domande approvate', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('approvedApplication', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItemDoubleStatsBeneficiary">
                        <span>{__('Importo totale finanziato', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('averageResponseDays', 0)}
                            format={{
                                notation: 'compact',
                                compactDisplay: 'short',
                                roundingMode: 'trunc',
                                style: 'currency',
                                currency: 'EUR',
                                currencyDisplay: 'symbol'
                            }}
                            locales="it-IT"/></span>
                    </div>
                </div>
            </div>

            <div className="appPage__spacer"></div>

            {chartStats && !isEmpty(chartStats)
                ? <div className="appPageSection">
                    <h2>{__('Statistiche di sistema', 'gepafin')}</h2>
                    <div className="appPageSection columns">
                        <ChartRichiesteVsApprovate
                            title={__('Importi Richiesti VS Approvati', 'gepafin')}
                            data={chartStats.requestedVsApprovedAmount}/>
                        <ChartDomandePerStato
                            title={__('Domande per stato', 'gepafin')}
                            data={chartStats.applicationPerStatus}/>
                    </div>
                </div> : null}

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Ultime richieste di finanziamento', 'gepafin')}</h2>
                <BeneficiarioUltimeDomandeTable/>
            </div>
        </div>
    )
}

export default StatsBeneficiary;