import React, { forwardRef, useImperativeHandle, useEffect, useState, useMemo, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty, isNil, is } from 'ramda';
import { klona } from 'klona';
import { TZDate } from '@date-fns/tz';
import { wrap } from 'object-path-immutable';

// components
import FormField from '../../../../components/FormField';
import FormFieldRepeater from '../../../../components/FormFieldRepeater';
import FormFieldRepeaterFaq from '../../../../components/FormFieldRepeaterFaq';
import BandoEditFormActions from '../BandoEditFormActions';
import UnsavedChangesDetector from '../../../../components/UnsavedChangesDetector';
import { Toast } from 'primereact/toast';

// api
import BandoService from '../../../../service/bando-service';
import LookupdataService from '../../../../service/lookupdata-service';

// tools
import { isEmail } from '../../../../helpers/validators';

// store
import { storeSet } from '../../../../store';
import set404FromErrorResponse from '../../../../helpers/set404FromErrorResponse';
import getTimeParsedFromString from '../../../../helpers/getTimeParsedFromString';
import formatDateString from '../../../../helpers/formatDateString';
import EvaluationFormsService from '../../../../service/evaluation-forms-service';

const APP_HUB_ID = process.env.REACT_APP_HUB_ID;

const BandoEditFormStep1 = forwardRef(function ({ initialData, setInitialData, getFormErrors, status }, ref) {
    const navigate = useNavigate();
    const [aimedToOptions, setAimedToOptions] = useState([]);
    const [faqOptions, setFaqOptions] = useState([]);
    const [formInitialData, setFormInitialData] = useState(initialData);
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        register,
        trigger,
        getValues,
        clearErrors
    } = useForm({
        defaultValues: useMemo(() => {
            return formInitialData;
        }, [formInitialData]), mode: 'onChange'
    });
    const values = getValues();
    const toast = useRef(null);

    const onSubmit = () => {
    };

    const onSaveDraft = () => {
        trigger();
        let formData = klona(getValues());
        formData.dates = [];

        if (formData.startDate) {
            let starDate;

            if (is(String, formData.startDate)) {
                starDate = formData.startDate;
            } else {
                starDate = formatDateString(formData.startDate);
            }

            formData = wrap(formData).insert(['dates'], starDate, 0).value();
        }
        if (formData.endDate) {
            let endDate;

            if (is(String, formData.endDate)) {
                endDate = formData.endDate;
            } else {
                endDate = formatDateString(formData.endDate);
            }

            formData = wrap(formData).insert(['dates'], endDate, 1).value();
        }
        if (!isNil(formData.startTime)) {
            if (!is(String, formData.startTime)) {
                const tzAwareDate = new TZDate(formData.startTime, 'Europe/Berlin');
                formData.startTime = tzAwareDate.toISOString().substring(11, 16);
            }
        }
        if (!isNil(formData.endTime)) {
            if (!is(String, formData.endTime)) {
                const tzAwareDate = new TZDate(formData.endTime, 'Europe/Berlin');
                formData.endTime = tzAwareDate.toISOString().substring(11, 16);
            }
        }

        delete formData.startDate;
        delete formData.endDate;

        storeSet.main.setAsyncRequest();
        if (!formData.id) {
            BandoService.createBando(formData, createCallback, errCreateCallback);
        } else {
            BandoService.updateBandoStep1(formData.id, formData, createCallback, errCreateCallback);
        }
    }

    const createCallback = (data) => {
        storeSet.main.unsetAsyncRequest();
        if (data.status === 'SUCCESS') {
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: __('Il bando è stato aggiornato correttamente!', 'gepafin')
                });
            }
            const values = getValues();
            if (!values.id && data.data.id) {
                storeSet.main.setAsyncRequest();
                const sampleFormData = {
                    label: `Evaluation form for call #${data.data.id}`,
                    content: []
                }
                EvaluationFormsService.createFormForCall(data.data.id, sampleFormData, createFormCallback, errCreateFormCallback)
            } else {
                setFormInitialData(data.data);
                setInitialData(data.data);
            }
        }
    }

    const errCreateCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const createFormCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            navigate(`/bandi/${resp.data.callId}`);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errCreateFormCallback = (resp) => {
        set404FromErrorResponse(resp);
        storeSet.main.unsetAsyncRequest();
    }

    const openPreview = () => {
        navigate(`/bandi/${values.id}/preview`);
    }

    const openPreviewEvaluation = () => {
        navigate(`/bandi/${values.id}/preview-evaluation`);
    }

    const lookupdataCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const aimedTo = data.data
                .filter(o => o.type === 'AIMED_TO')
                .map(o => {
                    delete o.type;
                    return {
                        ...o,
                        lookUpDataId: o.id,
                        id: null
                    };
                });
            setAimedToOptions(aimedTo);
            const faqItems = data.data
                .filter(o => o.type === 'FAQ')
                .map(o => {
                    delete o.type;
                    return {
                        ...o,
                        lookUpDataId: o.id,
                        id: null
                    };
                });
            setFaqOptions(faqItems);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errLookupdataCallback = (data) => {
        storeSet.main.unsetAsyncRequest();
    }

    const shouldDisableField = (fieldName) => {
        return values.status === 'PUBLISH' &&
            ![
                'descriptionShort', 'descriptionLong', 'documentationRequested', 'threshold',
                'aimedTo', 'criteria', 'docs', 'checklist', 'faq', 'amount', 'amountMin', 'amountMax',
                'email', 'phoneNumber', 'checkList', 'images', 'numberOfCheck', 'productId'
            ].includes(fieldName)
    }

    useImperativeHandle(
        ref,
        () => {
            return {
                isFormValid: () => {
                    return isValid;
                },
                getErrors: () => {
                    return errors;
                },
                getValues: () => {
                    return getValues();
                }
            }
        }, [errors, isValid]);

    useEffect(() => {
        storeSet.main.formInitialData(initialData);
        setFormInitialData(initialData);
    }, [initialData]);

    useEffect(() => {
        const newFormData = klona(formInitialData);
        if (!isNil(formInitialData.dates) && formInitialData.dates.length) {
            if (newFormData.dates[0]) {
                newFormData.startDate = is(String, newFormData.dates[0]) ? new Date(newFormData.dates[0]) : (newFormData.dates[0] ? newFormData.dates[0] : '');
            }
            if (newFormData.dates[1]) {
                newFormData.endDate = is(String, newFormData.dates[1]) ? new Date(newFormData.dates[1]) : (newFormData.dates[1] ? newFormData.dates[1] : '');
            }
        }
        if (!isNil(formInitialData.startTime) && !isEmpty(formInitialData.startTime)) {
            newFormData.startTime = is(String, formInitialData.startTime)
                ? getTimeParsedFromString(formInitialData.startTime)
                : formInitialData.startTime;
        }
        if (!isNil(formInitialData.endTime) && !isEmpty(formInitialData.endTime)) {
            newFormData.endTime = is(String, formInitialData.endTime)
                ? getTimeParsedFromString(formInitialData.endTime)
                : formInitialData.endTime;
        }

        Object.keys(newFormData).map(v => setValue(v, newFormData[v]));
    }, [formInitialData]);

    useEffect(() => {
        trigger().then(() => clearErrors());
        LookupdataService.getItems(lookupdataCallback, errLookupdataCallback, [['type', ['AIMED_TO', 'FAQ']]]);

        return () => {
            storeSet.main.formInitialData({});
        }
    }, []);

    return (
        <form className="appForm" onSubmit={handleSubmit(onSubmit)}>
            <UnsavedChangesDetector getValuesFn={getValues}/>
            <FormField
                type="switch"
                disabled={shouldDisableField('confidi')}
                fieldName="confidi"
                label={__('Bando Confidi', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['confidi']}
                onLabel={__('Si', 'gepafin')}
                offLabel={__('No', 'gepafin')}
            />

            <FormField
                type="textinput"
                disabled={shouldDisableField('name')}
                fieldName="name"
                label={__('Titolo del Bando', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['name']}
                config={{ required: __('È obbligatorio', 'gepafin') }}
            />

            <FormField
                type="wysiwyg"
                disabled={shouldDisableField('descriptionShort')}
                fieldName="descriptionShort"
                label={__('Descrizione breve', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['descriptionShort']}
                config={{
                    required: __('È obbligatorio', 'gepafin')
                }}
            />

            <FormField
                type="wysiwyg"
                disabled={shouldDisableField('descriptionLong')}
                fieldName="descriptionLong"
                label={__('Descrizione completa', 'gepafin')}
                control={control}
                rows={7}
                errors={errors}
                defaultValue={values['descriptionLong']}
                config={{
                    required: __('È obbligatorio', 'gepafin'),
                    minLength: 200
                }}
                infoText={__('Almeno 200 caratteri', 'gepafin')}
            />

            <FormFieldRepeater
                data={values['aimedTo']}
                disabled={shouldDisableField('aimedTo')}
                setDataFn={setValue}
                fieldName="aimedTo"
                options={aimedToOptions}
                errors={errors}
                register={register}
                trigger={trigger}
                config={{
                    validate: {
                        minOneItem: v => (v && !isEmpty(v)) || __('Almeno 1 tipo di destinatari', 'gepafin'),
                        noEmptyValue: v => v.filter(o => isEmpty(o.value)).length === 0 || __('Non lasciare il valore vuoto', 'gepafin')
                    }
                }}
                label={<>{__('A chi si rivolge', 'gepafin')}*
                    <span>{__('(almeno 1 tipo di destinatari)', 'gepafin')}</span></>}
            />

            <FormField
                type="wysiwyg"
                disabled={shouldDisableField('documentationRequested')}
                fieldName="documentationRequested"
                label={__('Documentazione richiesta', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['documentationRequested']}
                config={{
                    required: __('È obbligatorio', 'gepafin')
                }}
            />

            {/*<FormField
                type="datepickerrange"
                disabled={shouldDisableField()}
                fieldName="dates"
                label={__('Dati di pubblicazione', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['dates']}
                config={{ required: __('È obbligatorio', 'gepafin') }}
            />*/}
            <div className="appForm__cols">
                <FormField
                    type="datepicker"
                    disabled={shouldDisableField('startDate')}
                    fieldName="startDate"
                    label={__('Data di inizio', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['startDate']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                />

                <FormField
                    type="datepicker"
                    disabled={shouldDisableField('endDate')}
                    fieldName="endDate"
                    label={__('Data di fine', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['endDate']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                />
            </div>

            <div className="appForm__cols">
                <FormField
                    type="datepicker"
                    disabled={shouldDisableField('startTime')}
                    fieldName="startTime"
                    label={__('Ora di inizio', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['startTime']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                    timeOnly={true}
                />

                <FormField
                    type="datepicker"
                    disabled={shouldDisableField('endTime')}
                    fieldName="endTime"
                    label={__('Ora di fine', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['endTime']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                    timeOnly={true}
                />
            </div>

            <div className="appForm__cols">
                <FormField
                    type="numberinput"
                    disabled={shouldDisableField('amount')}
                    fieldName="amount"
                    label={__('Dotazione del Bando', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['amount']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                    inputgroup={true}
                    icon="€"
                />

                <FormField
                    type="numberinput"
                    disabled={shouldDisableField('amountMin')}
                    fieldName="amountMin"
                    label={__('Importo minimo per Progetto', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['amountMin']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                    inputgroup={true}
                    icon="€"
                />

                <FormField
                    type="numberinput"
                    disabled={shouldDisableField('amountMax')}
                    fieldName="amountMax"
                    label={__('Importo massimo per Progetto', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['amountMax']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                    inputgroup={true}
                    icon="€"
                />
            </div>

            <div className="appForm__cols">
                <FormField
                    type="textinput"
                    disabled={shouldDisableField('email')}
                    fieldName="email"
                    label={__('Email', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['email']}
                    config={{
                        required: __('È obbligatorio', 'gepafin'),
                        validate: {
                            isEmail
                        }
                    }}
                />

                <FormField
                    type="textinput"
                    disabled={shouldDisableField('phoneNumber')}
                    fieldName="phoneNumber"
                    label={__('Telefono', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['phoneNumber']}
                    config={{
                        pattern: /^[0-9]/
                    }}
                    inputgroup={true}
                    icon="+39"
                />
            </div>

            <div className="appForm__cols">
                <FormField
                    type="numberinput"
                    disabled={shouldDisableField('numberOfCheck')}
                    fieldName="numberOfCheck"
                    label={__('Quantità dei checklist per creare un soccorso', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['numberOfCheck']}
                    config={{
                        required: __('È obbligatorio', 'gepafin'),
                    }}
                />

                {APP_HUB_ID !== 't7jh5wfg9QXylNaTZkPoE'
                    ? <FormField
                        type="numberinput"
                        disabled={shouldDisableField('productId')}
                        fieldName="productId"
                        label={__('Prodotto ID dentro Odessa', 'gepafin')}
                        control={control}
                        errors={errors}
                        defaultValue={values['productId']}
                        config={{
                            required: __('È obbligatorio', 'gepafin'),
                        }}
                    /> : null}
            </div>

            <FormFieldRepeaterFaq
                data={values['faq']}
                disabled={shouldDisableField('faq')}
                setDataFn={setValue}
                fieldName="faq"
                options={faqOptions}
                errors={errors}
                register={register}
                trigger={trigger}
                config={{
                    validate: {
                        noEmptyValue: v => v
                            .filter(o => isEmpty(o.question) || isEmpty(o.answer)).length === 0 || __('Non lasciare il valore vuoto', 'gepafin')
                    }
                }}
                label={__('FAQ', 'gepafin')}/>

            <div className="appPage__spacer"></div>

            <div className="appPageSection__hr">
                <span>{__('Azioni', 'gepafin')}</span>
            </div>

            <Toast ref={toast}/>
            <BandoEditFormActions
                id={values.id}
                status={status}
                submitFn={onSaveDraft}
                openPreview={openPreview}
                openPreviewEvaluation={openPreviewEvaluation}/>
        </form>
    )
})

export default BandoEditFormStep1;