import { InputNumber } from 'primereact/inputnumber';

const NumericFormulaCell = ({ getValue, row: { index }, column: { id }, table }) => {
    const initialValue = getValue();
    const disabled = table.options.meta?.disabled;

    const onFocus = (e) => {
        e.target.select();
    }

    const onChange = (value) => {
        table.options.meta?.updateData(index, id, value);
    };

    return (
        <InputNumber
            disabled={disabled}
            value={initialValue ?? 0}
            onValueChange={(e) => onChange(e.value)}
            onFocus={onFocus}
            minFractionDigits={0}
            maxFractionDigits={2}
            locale='it-IT'
            useGrouping={false}
            showButtons
        />
    );
};

export default NumericFormulaCell;