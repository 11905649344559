import React from 'react';
import { __ } from '@wordpress/i18n';
import { isNil } from 'ramda';

// tools
import getDateTimeFromISOstring from '../../../../helpers/getDateTimeFromISOstring';
import getDateFromISOstring from '../../../../helpers/getDateFromISOstring';
import getBandoLabel from '../../../../helpers/getBandoLabel';

const APP_HUB_ID = process.env.REACT_APP_HUB_ID;

const ApplicationInfo = ({ data }) => {
    return (
        !isNil(data)
            ? <div className="appPageSection__withBorder columns">
                <p className="appPageSection__pMeta">
                    <span>{__('ID domanda', 'gepafin')}</span>
                    <span>{data.applicationId}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Protocollo', 'gepafin')}</span>
                    <span>{data.protocolNumber}</span>
                </p>
                {APP_HUB_ID !== 't7jh5wfg9QXylNaTZkPoE'
                    ? <p className="appPageSection__pMeta">
                        <span>{__('NDG', 'gepafin')}</span>
                        <span>{data.ndg}</span>
                    </p> : null}
                {APP_HUB_ID !== 't7jh5wfg9QXylNaTZkPoE'
                    ? <p className="appPageSection__pMeta">
                        <span>{__('Appuntamento', 'gepafin')}</span>
                        <span>{data.appointmentId}</span>
                    </p> : null}
                <p className="appPageSection__pMeta">
                    <span>{__('Bando', 'gepafin')}</span>
                    <span>{data.callName}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Referente Aziendale', 'gepafin')}</span>
                    <span>{data.beneficiary}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Azienda Beneficiaria', 'gepafin')}</span>
                    <span>{data.companyName}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Data ricezione', 'gepafin')}</span>
                    <span>{getDateTimeFromISOstring(data.submissionDate)}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Data assegnazione', 'gepafin')}</span>
                    <span>{getDateTimeFromISOstring(data.assignedAt)}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Aassegnato a', 'gepafin')}</span>
                    <span>{data.assignedUserName}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Scadenza Valutazione', 'gepafin')}</span>
                    <span>{getDateFromISOstring(data.evaluationEndDate)}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Stato', 'gepafin')}</span>
                    <span>{getBandoLabel(data.applicationStatus)}</span>
                </p>
            </div> : null
    )
}

export default ApplicationInfo;