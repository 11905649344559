import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { is, isEmpty, uniq } from 'ramda';

// store
import { useStore } from '../../../../store';

// api
import ApplicationService from '../../../../service/application-service';

// tools
import getNumberWithCurrency from '../../../../helpers/getNumberWithCurrency';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import translationStrings from '../../../../translationStringsForComponents';


const BeneficiarioUltimeDomandeTable = () => {
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const [items, setItems] = useState(null);
    // eslint-disable-next-line
    const [filters, setFilters] = useState(null);
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    // eslint-disable-next-line
    const [statuses, setStatuses] = useState([]);
    const perPage = 5;

    const getPaginationQuery = (status = 'DRAFT', curPage = 1) => {
        return {
            "globalFilters": {
                //"year": 0,
                "page": curPage,
                //"search": "",
                "limit": perPage,
            },
            //"daysRange": 0,
            "status": status
        }
    }

    useEffect(() => {
        if (!isEmpty(chosenCompanyId) && chosenCompanyId !== 0 && !localAsyncRequest) {
            const bodyParams = getPaginationQuery(
                ['SOCCORSO', 'APPROVED', 'REJECTED', 'EVALUATION', 'SUBMIT'],
                1
            );

            setLocalAsyncRequest(true);
            ApplicationService.getApplicationsPaginated(bodyParams, getApplCallback, errGetApplCallback, [
                ['companyId', chosenCompanyId],
                ['statuses', ['SOCCORSO', 'APPROVED', 'REJECTED', 'EVALUATION', 'SUBMIT']] // 'NDG', 'ADMISSIBLE', 'APPOINTMENT'
            ]);
        }
    }, [chosenCompanyId]);

    const getApplCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            if (resp.data && is(Array, resp.data.body)) {
                setItems(getFormattedBandiData(resp.data.body));
                setStatuses(uniq(items.map(o => o.status)))
                initFilters();
            }
        }
        setLocalAsyncRequest(false);
    }

    const errGetApplCallback = () => {
        setLocalAsyncRequest(false);
    }

    const getFormattedBandiData = (data) => {
        return [...(data || [])].map((d) => {
            d.callEndDate = new Date(d.callEndDate);
            d.modifiedDate = new Date(d.modifiedDate);
            d.submissionDate = new Date(d.submissionDate);

            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            year: 'numeric'
        });
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            callTitle: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            companyName: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            modifiedDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            callEndDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            }
        });
    };

    const dateSubmissionBodyTemplate = (rowData) => {
        return formatDate(rowData.submissionDate);
    };

    const importoBodyTemplate = (rowData) => {
        return getNumberWithCurrency(rowData.amountRequested);
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    return (
        <div className="appPageSection__table">
            <DataTable value={items} showGridlines rows={5} loading={localAsyncRequest} dataKey="id"
                       stripedRows
                       emptyMessage={translationStrings.emptyMessage}>
                <Column field="callTitle" header={__('Bando', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Anno', 'gepafin')} dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateSubmissionBodyTemplate}/>
                <Column field="amountRequested"
                        header={__('Importo finanziato', 'gepafin')}
                        style={{ minWidth: '7rem' }}
                        body={importoBodyTemplate}/>
                <Column field="status" header={__('Stato progetto', 'gepafin')}
                        style={{ minWidth: '7rem' }} body={statusBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default BeneficiarioUltimeDomandeTable;
