import React, { useRef, useState, useEffect } from 'react';
import { __, sprintf } from '@wordpress/i18n';
import { useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { isEmpty } from 'ramda';
import { useNavigate, useSearchParams } from 'react-router-dom';

// tools
import AuthenticationService from '../../service/authentication-service';

// store
import { useStore } from '../../store';

// components
import FormField from '../../components/FormField';
import LogoIcon from '../../icons/LogoIcon';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';

const APP_HUB_ID = process.env.REACT_APP_HUB_ID;

const ResetPassword = () => {
    const navigate = useNavigate();
    const token = useStore().main.token();
    const [loading, setLoading] = useState(false);
    const [resetPassToken, setResetPassToken] = useState('');
    const [resetPassEmail, setResetPassEmail] = useState('');
    const errorMsgs = useRef(null);
    let [searchParams] = useSearchParams();
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        register,
        setValue
    } = useForm({ mode: 'onChange' });

    const gotToLoginAdmin = () => {
        navigate('/loginadmin');
    }

    const onSubmit = (formData) => {
        errorMsgs.current.clear();
        setLoading(true);
        const request = {
            ...formData,
            hubUuid: APP_HUB_ID
        }

        if (request.token && !isEmpty(request.token)) {
            AuthenticationService.resetPassword(request, getCallbackReset, errCallback);
        } else {
            AuthenticationService.forgotPassword(request, getCallback, errCallback);
        }
    };

    const getCallbackReset = (data) => {
        if (data.status === 'SUCCESS') {
            errorMsgs.current.show([
                {
                    sticky: true, severity: 'success', summary: '',
                    detail: data.message,
                    closable: true
                }
            ]);
        } else {
            errorMsgs.current.show([
                {
                    sticky: true, severity: 'error', summary: '',
                    detail: data.message,
                    closable: true
                }
            ]);
        }
        setLoading(false);
    }

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setResetPassToken(data.data)
        } else {
            errorMsgs.current.show([
                {
                    sticky: true, severity: 'error', summary: '',
                    detail: data.message,
                    closable: true
                }
            ]);
        }
        setLoading(false);
    }

    const errCallback = (err) => {
        errorMsgs.current.show([
            {
                sticky: true, severity: 'error', summary: '',
                detail: sprintf(__('%s', 'gepafin'), err.message),
                closable: true
            }
        ]);
        setLoading(false);
    }

    useEffect(() => {
        if (!isEmpty(token)) {
            setLoading(true);
            window.location.replace('/')
        }
    }, [token]);

    useEffect(() => {
        console.log(resetPassToken, resetPassEmail);
        reset();
        setValue('token', resetPassToken);
        setValue('email', resetPassEmail);
    }, [resetPassToken, resetPassEmail]);

    useEffect(() => {
        const token = searchParams.get('token');
        const email = searchParams.get('email');
        setResetPassToken(token);
        setResetPassEmail(email);
    }, [searchParams]);

    return (
        <div className={classNames(['appPage', 'appPageLogin'])}>
            <div className="appPageLogin__wrapper">
                <LogoIcon/>

                <h1>{__('Password dimenticata', 'gepafin')}</h1>

                <Messages ref={errorMsgs}/>

                <div className="appPage__spacer"></div>

                <form className="appForm" onSubmit={handleSubmit(onSubmit)}>
                    <FormField
                        type="textinput"
                        fieldName="email"
                        label={__('Email', 'gepafin')}
                        control={control}
                        errors={errors}
                        config={{ required: __('È obbligatorio', 'gepafin') }}
                        placeholder="sample@example.com"
                    />

                    {resetPassToken && !isEmpty(resetPassToken)
                        ? <input
                            type="hidden"
                            name="token"
                            {...register('token', {
                                required: true
                            })}
                        /> : null}

                    {resetPassToken && !isEmpty(resetPassToken)
                        ? <FormField
                            type="password"
                            fieldName="newPassword"
                            label={__('Password', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin'),
                                validate: {
                                    passEqual: (value, values) => values.confirmPassword === value
                                }
                            }}
                        /> : null}

                    {resetPassToken && !isEmpty(resetPassToken)
                        ? <FormField
                            type="password"
                            inputtype="password"
                            fieldName="confirmPassword"
                            label={__('Conferma password', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin'),
                                validate: {
                                    passEqual: (value, values) => values.newPassword === value
                                }
                            }}
                        /> : null}

                    <Button
                        label={__('Invia', 'gepafin')}
                        disabled={loading}/>

                    <Button
                        label={__('Accedi', 'gepafin')}
                        link onClick={gotToLoginAdmin}/>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword;