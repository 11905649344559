import React from 'react';
import { isNil } from 'ramda';
import { classNames } from 'primereact/utils';

// components
import TextInput from './components/TextInput';
import TextArea from './components/TextArea';
import Datepicker from './components/Datepicker';
import DatepickerRange from './components/DatepickerRange';
import FileuploadAsync from './components/FileuploadAsync';
import NumberInput from './components/NumberInput';
import Switch from './components/Switch';
import Select from './components/Select';
import Radio from './components/Radio';
import Wysiwyg from './components/Wysiwyg';
import Checkboxes from './components/Checkboxes';
import Fileupload from './components/Fileupload';
import Table from './components/Table';
import PasswordField from './components/PasswordField';
import CriteriaTable from './components/CriteriaTable';

const FormField = (props) => {
    const fields = {
        textinput: TextInput,
        textarea: TextArea,
        datepicker: Datepicker,
        datepickerrange: DatepickerRange,
        fileuploadasync: FileuploadAsync,
        fileupload: Fileupload,
        numberinput: NumberInput,
        switch: Switch,
        select: Select,
        radio: Radio,
        wysiwyg: Wysiwyg,
        checkboxes: Checkboxes,
        table: Table,
        criteria_table: CriteriaTable,
        password: PasswordField
    }
    const Comp = !isNil(fields[props.type]) ? fields[props.type] : null;

    return (!isNil(Comp)
            ? <div className={classNames(['appForm__field', props.type])}>
                <Comp {...props} />
            </div>
            : null
    )
}

export default FormField;