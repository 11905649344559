import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from '../ItemTypes';
import { __ } from '@wordpress/i18n';
import { head, isEmpty } from 'ramda';
import { klona } from 'klona';

// store
import { storeSet, useStore } from '../../../../store';

// tools
import uniqid from '../../../../helpers/uniqid';

// components
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import BuilderElementProperLabel from '../BuilderElementProperLabel';

const BuilderElement = ({ id, name, label, index, bandoStatus }) => {
    const draggingElementId = useStore().main.draggingElementId();
    const selectedElement = useStore().main.selectedElement();
    const ref = useRef(null);
    const elements = useStore().main.formElements();
    const element = head(elements.filter(o => o.id === id));
    const [isVariable, setIsVariable] = useState('secondary');
    const [isFormula, setIsFormula] = useState('secondary');
    const [variableName, setVariableName] = useState('secondary');
    const [formulaName, setFormulaName] = useState('secondary');
    const [isRequestedAmount, setIsRequestedAmount] = useState(false);
    const [isDelegation, setIsDelegation] = useState(false);

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.FIELD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        drop(item, monitor) {
            storeSet.main.draggingElementId(0);
            return item;
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index

            if (dragIndex > -1) {
                const hoverIndex = index

                if (dragIndex === hoverIndex) {
                    return
                }

                const hoverBoundingRect = ref.current?.getBoundingClientRect()
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                const clientOffset = monitor.getClientOffset()
                const hoverClientY = clientOffset.y - hoverBoundingRect.top

                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return
                }

                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return
                }

                move(dragIndex, hoverIndex, item)
                item.index = hoverIndex
            } else {
                let hoverIndex = index

                const hoverBoundingRect = ref.current?.getBoundingClientRect()
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                const clientOffset = monitor.getClientOffset()
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;

                if (hoverClientY > hoverMiddleY) {
                    hoverIndex = hoverIndex + 1;
                }

                move(dragIndex, hoverIndex, item)
                item.index = hoverIndex;
            }
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.FIELD,
        item: () => {
            return { id, name, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const move = (dragIndex, hoverIndex, item) => {
        storeSet.main.moveElement(dragIndex, hoverIndex, item);
    }

    const openSettings = () => {
        storeSet.main.activeElement(id);
    }

    const selectElement = () => {
        storeSet.main.selectedElement(id);
    }

    const duplicateElement = useCallback(() => {
        const duplicatedElement = head(elements.filter(o => o.id === id));

        if (duplicatedElement) {
            const copyElement = klona(duplicatedElement);
            copyElement.settings = copyElement.settings.map((o) => {
                if (o.name === 'label') {
                    o.value = `Copy - ${o.value}`
                }
                return o;
            })
            copyElement.id = uniqid();
            const originalIndex = elements.map(o => o.id).indexOf(id);
            const newElements = [...elements].toSpliced(originalIndex + 1, 0, copyElement);
            storeSet.main.formElements(newElements);
        }
    }, [elements]);

    const remove = () => {
        storeSet.main.removeElement(id);
    }

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    useEffect(() => {
        const variable = head(element.settings.filter(o => o.name === 'variable'));
        const formula = head(element.settings.filter(o => o.name === 'formula'));
        const isRequestedAmount = head(element.settings.filter(o => o.name === 'isRequestedAmount'));
        const isDelegation = head(element.settings.filter(o => o.name === 'isDelegation'));

        if (variable && !isEmpty(variable.value)) {
            setIsVariable('warning');
            setVariableName(variable.value)
        }

        if (formula && !isEmpty(formula.value)) {
            setIsFormula('warning');
            setFormulaName(formula.value)
        }

        if (isRequestedAmount && !isEmpty(isRequestedAmount.value) && isRequestedAmount.value) {
            setIsRequestedAmount('tertiary');
        }

        if (isDelegation && !isEmpty(isDelegation.value) && isDelegation.value) {
            setIsDelegation('tertiary');
        }
    }, [elements]);

    return (
        draggingElementId === id
            ? <div ref={ref} className="formBuilder__elementNew">
                {__('lascia qui', 'gepafin')}
            </div>
            : <div ref={ref}
                   className={`formBuilder__element${selectedElement === id ? ' selected' : ''}`}
                   style={{ opacity }}
                   onClick={selectElement}
                   data-handler-id={handlerId}>
                <div className="meta">
                    <div className="tagHeader">
                        <Tag value={label} severity="info"/>
                        {['numberinput', 'criteria_table'].includes(name)
                            ? <Tag value="var" severity={isVariable} title={variableName}/> : null}
                        {name === 'numberinput'
                            ? <Tag value="f(x)" severity={isFormula} title={formulaName}/> : null}
                        {isRequestedAmount
                            ? <Tag value="importo" severity={isRequestedAmount}/> : null}
                        {isDelegation
                            ? <Tag value="delega" severity={isDelegation}/> : null}
                    </div>
                    <BuilderElementProperLabel id={id} defaultLabel={label}/>
                </div>
                <div className="actions">
                    <Button icon="pi pi-clone" onClick={duplicateElement} outlined severity="success"/>
                    <Button icon="pi pi-cog" onClick={openSettings} outlined severity="info"/>
                    <Button icon="pi pi-trash" disabled={bandoStatus === 'PUBLISH'} onClick={remove} outlined severity="danger"/>
                </div>
            </div>
    )
}

export default BuilderElement;