const getBandoSeverity = (status) => {
    switch (status) {
        case 'ACTIVE':
            return 'success';

        case 'SUBMIT':
            return 'success';

        case 'PUBLISH':
            return 'success';

        case 'APPROVED':
            return 'success';

        case 'READY_TO_PUBLISH':
            return 'info';

        case 'READY':
            return 'info';

        case 'NDG':
            return 'info';

        case 'APPOINTMENT':
            return 'info';

        case 'ADMISSIBLE':
            return 'info';

        case 'RESPONSE_RECEIVED':
            return 'warning';

        case 'SOCCORSO':
            return 'warning';

        case 'DRAFT':
            return 'warning';

        case 'AWAITING':
            return 'warning';

        case 'AWAIT':
            return 'warning';

        case 'OPEN':
            return 'warning';

        case 'ASSIGNED':
            return 'warning';

        case 'EVALUATION':
            return 'info';

        case 'EXPIRED':
            return 'closed';

        case 'CLOSE':
            return 'closed';

        case 'REJECTED':
            return 'danger';

        default:
            return 'info';
    }
};

export default getBandoSeverity;