import React, { useEffect, useState } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate } from 'react-router-dom';
import { pathOr } from 'ramda';
import NumberFlow from '@number-flow/react';

// api
import DashboardService from '../../service/dashboard-service';

// components
import LatestBandiTable from './components/LatestBandiTable';
import { Button } from 'primereact/button';
import AllDomandeTable from '../Domande/components/AllDomandeTable';
import DraftApplicationsTable from './components/DraftApplicationsTable';
import ChartDomandePerBando from '../../components/ChartDomandePerBando';
import ChartStatoDomande from '../../components/ChartStatoDomande';

const Dashboard = () => {
    const navigate = useNavigate();
    const [mainStats, setMainStats] = useState({});
    const [chartStats, setChartStats] = useState({});

    const onGoToCreateNewBando = () => {
        navigate('/bandi/new');
    }

    const onGoToUsers = () => {
        navigate('/utenti');
    }

    /*const onGoToStats = () => {
        console.log('onGoToStats')
    }

    const onGoToSettings = () => {
        console.log('onGoToSettings')
    }*/

    const getStatValue = (key, fallback = '') => {
        return pathOr(fallback, [key], mainStats);
    }

    const getStats = (data) => {
        if (data.status === 'SUCCESS') {
            setMainStats(data.data.widget1);
            setChartStats(data.data.widgetBars);
        }
    }

    const errGetStats = () => {
    }

    useEffect(() => {
        DashboardService.getAdminStats(getStats, errGetStats);
    }, []);

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Dashboard', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection statsBigBadges">
                <h2>{__('Panoramica di Sistema', 'gepafin')}</h2>
                <div className="statsBigBadges__grid">
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Bandi attivi', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfActiveCalls', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Utenti registrati', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfResgisteredUsers', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Domande in pre-istruttoria', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfSubmittedApplications', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Domande in bozza', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfDraftApplications', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Aziende', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfCompany', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Totale finanziamenti attivi', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('totalActiveFinancing', 0)}
                            format={{
                                notation: 'compact',
                                compactDisplay: 'short',
                                roundingMode: 'trunc',
                                style: 'currency',
                                currency: 'EUR',
                                currencyDisplay: 'symbol'
                            }}
                            locales="it-IT"/></span>
                    </div>
                </div>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Ultimi bandi pubblicati', 'gepafin')}</h2>
                <LatestBandiTable/>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Ultime domande pubblicate', 'gepafin')}</h2>
                <AllDomandeTable/>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Domande in bozza', 'gepafin')}</h2>
                <DraftApplicationsTable/>
            </div>

            {/*<div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Attività Recenti Utenti', 'gepafin')}</h2>
                <LatestUsersActivityTable/>
            </div>*/}

            <div className="appPage__spacer"></div>

            {chartStats.applicationPerCall
                ? <div className="appPageSection">
                    <h2>{__('Statistiche di sistema', 'gepafin')}</h2>
                    <div className="appPageSection columns">
                        <ChartDomandePerBando
                            title={__('Domande per bando', 'gepafin')}
                            data={chartStats.applicationPerCall}/>
                        <ChartStatoDomande
                            title={__('Stato domande', 'gepafin')}
                            data={chartStats.applicationPerStatus}/>
                    </div>
                </div> : null}

            <div className="appPage__spacer"></div>

            <div className="appPageSection__hr">
                <span>{__('Azioni rapide', 'gepafin')}</span>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <div className="appPageSection__actions">
                    <Button
                        onClick={onGoToCreateNewBando}
                        label={__('Crea nuovo bando', 'gepafin')} icon="pi pi-plus" iconPos="right"/>
                    <Button
                        onClick={onGoToUsers}
                        label={__('Gestione utenti', 'gepafin')} icon="pi pi-users" iconPos="right"/>
                    {/*<Button
                        disabled={true}
                        onClick={onGoToStats}
                        label={__('Report mensile', 'gepafin')} icon="pi pi-chart-bar" iconPos="right"/>
                    <Button
                        disabled={true}
                        onClick={onGoToSettings}
                        label={__('Configurazione', 'gepafin')} icon="pi pi-cog" iconPos="right"/>*/}
                </div>
            </div>
        </div>
    )
}

export default Dashboard;