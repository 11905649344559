import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { __, sprintf } from '@wordpress/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { is, isEmpty, isNil, sum, pathOr, head, pluck } from 'ramda';
import { klona } from 'klona';
import { wrap } from 'object-path-immutable';
import { useForm } from 'react-hook-form';
import { evaluate } from 'mathjs';
import equal from 'fast-deep-equal';

// store
import { storeGet, storeSet, useStore } from '../../store';

// api
import ApplicationEvaluationService from '../../service/application-evaluation-service';
import AmendmentsService from '../../service/amendments-service';
import AppointmentService from '../../service/appointment-service';

// tools
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';
import {
    isCAP,
    isCodiceFiscale,
    isEmail,
    isEmailPEC,
    isIBAN,
    isMarcaDaBollo,
    isPIVA,
    isUrl, maxChecks, minChecks, nonEmptyTables
} from '../../helpers/validators';
import getTokens from '../../helpers/getTokens';
import parseCommaDecimal from '../../helpers/parseCommaDecimal';
import renderWithDataVars from '../../helpers/renderWithDataVars';
import renderHtmlContent from '../../helpers/renderHtmlContent';
import formatDateString from '../../helpers/formatDateString';

// components
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Checkbox } from 'primereact/checkbox';
import { Editor } from 'primereact/editor';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import HelpIcon from '../../icons/HelpIcon';
import BlockingOverlay from '../../components/BlockingOverlay';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import ListOfFiles from './components/ListOfFiles';
import RepeaterFields from './components/RepeaterFields';
import ApplicationInfo from './components/ApplicationInfo';
import ApplicationDownloadFiles from './components/ApplicationDownloadFiles';
import FormField from '../../components/FormField';

const APP_EVALUATION_FLOW_ID = process.env.REACT_APP_EVALUATION_FLOW_ID;
const APP_HUB_ID = process.env.REACT_APP_HUB_ID;

const DomandaEditPreInstructor = () => {
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [isVisibleCriterionData, setIsVisibleCriterionData] = useState(0);
    const [criterionDataTitle, setCriterionDataTitle] = useState('');
    const [criterionDataContent, setCriterionDataContent] = useState('');
    const [isAdmissible, setIsAdmissible] = useState(false);
    const [connectedSoccorsoId, setConnectedSoccorsoId] = useState(0);
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [isVisibleCompleteDialog, setIsVisibleCompleteDialog] = useState(false);
    const [operationType, setOperationType] = useState('');
    const [motivation, setMotivation] = useState('');
    const [amountAccepted, setAmountAccepted] = useState(0);
    const [isVisibleAppointmentDialog, setIsVisibleAppointmentDialog] = useState(false);
    const [allFilesRated, setAllFilesRated] = useState(false);
    const [atLeastOneChecked, setAtLeastOneChecked] = useState(false);
    const [allChecksChecked, setAllChecksChecked] = useState(false);
    const [appointmentData, setAppointmentData] = useState({
        title: '',
        text: '',
        duration: 0,
        amount: 0
    });
    const [formData, setFormData] = useState([]);
    const [formId, setFormId] = useState(0);
    const [formInitialData, setFormInitialData] = useState(null);
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        trigger,
        register,
        getValues,
        watch,
        reset
    } = useForm({
        defaultValues: useMemo(() => {
            return formInitialData ? formInitialData : {}
        }, [formInitialData]),
        mode: 'onChange'
    });
    const validationFns = {
        isPIVA,
        isCodiceFiscale,
        isCAP,
        isIBAN,
        isEmail,
        isEmailPEC,
        isUrl,
        isMarcaDaBollo,
        minChecks,
        maxChecks,
        nonEmptyTables
    }
    const values = getValues();
    const formValues = watch();

    const goToEvaluationsPage = () => {
        navigate('/domande');
    }

    const updateFlagsForSoccorso = useCallback((data) => {
        let nonRatedFilesLength = 0;

        if (data.files) {
            const nonRatedFiles = data.files
                .map(el => el.valid)
                .filter(v => isNil(v));
            nonRatedFilesLength = nonRatedFiles.length;
        }

        if (data.amendmentDetails) {
            const nonRatedFiles = data.amendmentDetails
                .map(el => el.valid)
                .filter(v => isNil(v));
            nonRatedFilesLength = nonRatedFiles.length;
        }

        setAllFilesRated(nonRatedFilesLength === 0);

        if (data.evaluationVersion === 'V1') {
            if (data.checklist) {
                const checkedChecklistItems = data.checklist
                    .map(el => el.valid)
                    .filter(v => v);
                setAtLeastOneChecked(checkedChecklistItems.length > 0);
                setAllChecksChecked(checkedChecklistItems.length === data.checklist.length)
            }
        } else if (data.evaluationVersion === 'V2') {
            const minChecks = data.numberOfCheck;
            const formFieldsChecklist = formData
                .filter(o => head(o.settings.filter(s => s.name === 'isChecklistItem' && s.value)))
                .map(o => o.id);

            if (formFieldsChecklist.length >= minChecks) {
                const valuesTotal = formFieldsChecklist.map(v => formValues[v]);
                const valuesFirst = valuesTotal.toSpliced(minChecks);
                setAtLeastOneChecked(valuesTotal.filter(v => v === true).length === valuesTotal.length);
                setAllChecksChecked(valuesFirst.filter(v => v === true).length === valuesFirst.length)
            }
        }
    }, [formValues]);

    const doNewSoccorso = () => {
        if (connectedSoccorsoId !== 0) {
            navigate(`/domande/${id}/soccorso/${connectedSoccorsoId}`);
        } else {
            doSaveDraft(`/domande/${id}/aggiungi-soccorso/`)
        }
    }

    const getVersion = (resp) => {
        if (resp.status === 'SUCCESS') {
            if (resp.data.evaluationVersion === 'V1') {
                storeSet.main.setAsyncRequest();
                ApplicationEvaluationService.getEvaluationByApplId(getCallback, errGetCallback, [
                    ['applicationId', resp.data.applicationId]
                ]);
            } else if (resp.data.evaluationVersion === 'V2') {
                storeSet.main.setAsyncRequest();
                ApplicationEvaluationService.getEvaluationV2ByApplId(getCallback, errGetCallback, [
                    ['applicationId', resp.data.applicationId]
                ]);
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetVersion = (resp) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: resp.message
            });
        }
        set404FromErrorResponse(resp);
        storeSet.main.unsetAsyncRequest();
    }

    const getCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            setData(getFormattedData(resp.data));
            setMotivation(resp.data.motivation);
            updateFlagsForSoccorso(resp.data);

            if (resp.data.evaluationVersion === 'V2') {
                setFormData(resp.data.applicationEvaluationFormResponse.content);
                setFormId(resp.data.applicationEvaluationFormResponse.id);
                let formDataInitial = {};

                if (resp.data.applicationEvaluationFormResponse.formFields) {
                    const submitData = resp.data.applicationEvaluationFormResponse.formFields.map((o) => ({
                        fieldId: o.fieldId,
                        fieldValue: o.fieldValue
                    }));
                    formDataInitial = submitData.reduce((acc, cur) => {
                        if (cur.fieldValue) {
                            acc[cur.fieldId] = cur.fieldValue;
                        }
                        return acc;
                    }, formDataInitial);
                }

                reset();
                setFormInitialData(formDataInitial);
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetCallback = (resp) => {
        if (toast.current && resp.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: resp.message
            });
        }
        set404FromErrorResponse(resp);
        storeSet.main.unsetAsyncRequest();
    }

    const getFormattedData = (data) => {
        data.submissionDate = is(String, data.submissionDate) ? new Date(data.submissionDate) : (data.submissionDate ? data.submissionDate : '');
        data.evaluationDate = is(String, data.evaluationDate) ? new Date(data.evaluationDate) : (data.evaluationDate ? data.evaluationDate : '');
        return data;
    };

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-header" value="2"></button>
                <button className="ql-header" value="3"></button>
                <button className="ql-blockquote"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
            </span>
        );
    };

    const header = renderHeader();

    const updateEvaluationValue = (value, path, maxValue = null) => {
        let finalValue = value;

        if (maxValue || maxValue === 0) {
            finalValue = value > maxValue ? maxValue : value;
        }

        const newData = wrap(data).set(path, finalValue).value();
        setData(newData);
        updateFlagsForSoccorso(newData);
    }

    const getTransformedSubmitData = () => {
        const formValues = getValues();
        const usedFieldsIds = pluck('id', formData);
        return Object.keys(formValues)
            .filter(v => usedFieldsIds.includes(v))
            .reduce((acc, cur) => {
                const formField = head(formData.filter(o => o.id === cur));
                let fieldVal = formValues[cur];

                if (formValues[cur] && formValues[cur].toISOString) {
                    fieldVal = formatDateString(formValues[cur]);
                }

                fieldVal = isEmpty(fieldVal) ? null : fieldVal;
                if (formField && formField.name === 'fileupload') {
                    fieldVal = is(Array, fieldVal) ? fieldVal.map(o => o.id).join(',') : null;
                }
                acc.push({
                    'fieldId': cur,
                    'fieldValue': fieldVal
                });
                return acc;
            }, []);
    }

    const doSaveDraft = useCallback((doRedirect = '') => {
        if (data.evaluationVersion === 'V1') {
            const submitData = {
                criteria: klona(data.criteria),
                checklist: klona(data.checklist),
                files: klona(data.files),
                evaluationDocument: klona(data.evaluationDocument.map(o => ({
                        ...o,
                        fileValue: o.fileValue[0] ? o.fileValue[0].id : ''
                    })
                )),
                amendmentDetails: klona(data.amendmentDetails),
                note: data.note
            };

            ApplicationEvaluationService.updateEvaluation(
                data.assignedApplicationId,
                submitData,
                (data) => updateCallback(data, doRedirect),
                errUpdateCallback
            );
        } else if (data.evaluationVersion === 'V2') {
            const newFormValues = getTransformedSubmitData();
            const submitData = {
                formFields: newFormValues,
                files: klona(data.files),
                evaluationDocument: klona(data.evaluationDocument.map(o => ({
                        ...o,
                        fileValue: o.fileValue[0] ? o.fileValue[0].id : ''
                    })
                )),
                amendmentDetails: klona(data.amendmentDetails),
                note: data.note
            }

            ApplicationEvaluationService.updateEvaluationV2(
                data.assignedApplicationId,
                formId,
                submitData,
                (data) => updateCallback(data, doRedirect),
                errUpdateCallback
            );
        }
    }, [data]);

    const updateCallback = (data, doRedirect = '') => {
        if (data.status === 'SUCCESS') {
            setData(getFormattedData(data.data));
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
            if (!isEmpty(doRedirect)) {
                navigate(doRedirect);
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errUpdateCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const doApprove = () => {
        if (data.evaluationVersion === 'V1') {
            const submitData = {
                applicationStatus: 'APPROVED',
                criteria: klona(data.criteria),
                checklist: klona(data.checklist),
                files: klona(data.files),
                note: data.note,
                motivation,
                amountAccepted
            }

            setLoading(true);
            setIsVisibleCompleteDialog(false);
            ApplicationEvaluationService.updateEvaluation(
                data.assignedApplicationId,
                submitData,
                updateStatusCallback,
                errUpdateStatusCallback
            );
        } else if (data.evaluationVersion === 'V2') {
            const newFormValues = getTransformedSubmitData();
            const submitData = {
                formFields: newFormValues,
                files: klona(data.files),
                evaluationDocument: klona(data.evaluationDocument.map(o => ({
                        ...o,
                        fileValue: o.fileValue[0] ? o.fileValue[0].id : ''
                    })
                )),
                amendmentDetails: klona(data.amendmentDetails),
                note: data.note,
                motivation
            }

            setLoading(true);
            setIsVisibleCompleteDialog(false);
            ApplicationEvaluationService.updateEvaluationV2(
                data.assignedApplicationId,
                formId,
                submitData,
                updateStatusCallback,
                errUpdateStatusCallback
            );
        }
    }

    const doReject = () => {
        if (data.evaluationVersion === 'V1') {
            const submitData = {
                applicationStatus: 'REJECTED',
                criteria: klona(data.criteria),
                checklist: klona(data.checklist),
                files: klona(data.files),
                note: data.note,
                motivation
            }

            setLoading(true);
            setIsVisibleCompleteDialog(false);
            ApplicationEvaluationService.updateEvaluation(
                data.assignedApplicationId,
                submitData,
                updateStatusCallback,
                errUpdateStatusCallback
            );
        } else if (data.evaluationVersion === 'V2') {
            const newFormValues = getTransformedSubmitData();
            const submitData = {
                formFields: newFormValues,
                files: klona(data.files),
                evaluationDocument: klona(data.evaluationDocument.map(o => ({
                        ...o,
                        fileValue: o.fileValue[0] ? o.fileValue[0].id : ''
                    })
                )),
                amendmentDetails: klona(data.amendmentDetails),
                note: data.note,
                motivation
            }

            setLoading(true);
            setIsVisibleCompleteDialog(false);
            ApplicationEvaluationService.updateEvaluationV2(
                data.assignedApplicationId,
                formId,
                submitData,
                updateStatusCallback,
                errUpdateStatusCallback
            );
        }
    }

    const updateStatusCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setData(getFormattedData(data.data));
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
        }
        setLoading(false);
    }

    const errUpdateStatusCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        setLoading(false);
    }

    const displayCriterionData = (id) => {
        const criterion = head(data.criteria.filter(o => o.id === id));
        setCriterionDataTitle(criterion.label);
        const content = <div className="criterionRelatedData">
            <h3>{__('I campi correlati')}</h3>
            {criterion.criteriaMappedFields ? criterion.criteriaMappedFields.map(o => criteriaDataItem(o)) : null}
        </div>;
        setCriterionDataContent(content);
        setIsVisibleCriterionData(id);
    }

    const criteriaDataItem = (item) => {
        let content = '';

        switch (item.fieldName) {
            case 'fileupload' :
                content = <ul>
                    {item.fieldValue
                        ? item.fieldValue.map(o => <li key={o.id}>
                            {o.filePath ? <a href={o.filePath}>{o.name}</a> : null}
                        </li>)
                        : null}
                </ul>;
                break;
            case 'table' :
                const th = Object.keys(item.fieldValue[0]);
                content = <table>
                    <thead>
                    <tr>
                        {th.map(v => <th key={v}>{v}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {item.fieldValue
                        ? item.fieldValue.map((o, i) => <tr key={i}>
                            {Object.values(o).map(v => <td key={v}>{v}</td>)}
                        </tr>)
                        : null}
                    </tbody>
                </table>;
                break;
            default :
                content = item.fieldValue;
                break;
        }

        return <div key={item.id} className="criterionRelatedData__item">
            <strong>{item.fieldLabel}</strong>
            {content}
        </div>
    }

    const hideCriterionData = () => {
        setIsVisibleCriterionData(0);
        setCriterionDataTitle('');
        setCriterionDataContent('');
    }

    const getAmendmentsCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (data.data.length) {
                setConnectedSoccorsoId(data.data[0].id);
            }
        }
    }

    const errGetAmendmentsCallback = () => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
    }

    const shouldDisableField = (fieldName) => {
        return !['EVALUATION'].includes(data.applicationStatus)
            || (['ADMISSIBLE'].includes(data.applicationStatus) && fieldName !== 'criteria')
    }

    const headerCompleteDialog = () => {
        return 'approve' === operationType
            ? <span>{__('Confermare l\'approvazione', 'gepafin')}</span>
            : <span>{__('Confermare il rifiuto', 'gepafin')}</span>;
    }

    const hideCompleteDialog = () => {
        setIsVisibleCompleteDialog(false);
        setOperationType('');
        setMotivation('');
    }

    const footerCompleteDialog = () => {
        return <div>
            <Button type="button" label={__('Anulla', 'gepafin')} onClick={hideCompleteDialog} outlined/>
            <Button
                type="button"
                disabled={loading}
                label={__('Invia', 'gepafin')} onClick={'approve' === operationType ? doApprove : doReject}/>
        </div>
    }

    const initiateApproving = () => {
        setOperationType('approve');
        setIsVisibleCompleteDialog(true);

    }

    const initiateRejecting = () => {
        setOperationType('reject');
        setIsVisibleCompleteDialog(true);
    }

    const doCheckNDG = () => {
        storeSet.main.setAsyncRequest();
        doSaveDraft();
        setTimeout(() => {
            AppointmentService.getNdg(id, getNdgCallback, errGetNdgCallback);
        }, 100);
    }

    const getNdgCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (toast.current && data.message) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetNdgCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: data.status === 'SUCCESS' ? 'info' : 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const doCreateAppointment = () => {
        setAppointmentData({
            title: '',
            text: '',
            duration: 0,
            amount: 0
        });
        setIsVisibleAppointmentDialog(true);
    }

    const setFieldValue = (name, value) => {
        const newData = wrap(appointmentData).set(name, value).value();
        setAppointmentData(newData);
    }

    const headerAppointmentDialog = () => {
        return <span>{__('Crea appuntamento', 'gepafin')}</span>;
    }

    const hideAppointmentDialog = () => {
        setIsVisibleAppointmentDialog(false);
        setAppointmentData({});
    }

    const footerAppointmentDialog = () => {
        return <div>
            <Button type="button" label={__('Anulla', 'gepafin')} onClick={hideAppointmentDialog} outlined/>
            <Button
                type="button"
                disabled={loading}
                label={__('Invia', 'gepafin')} onClick={doCreateAppointmentRequest}/>
        </div>
    }

    const doCreateAppointmentRequest = () => {
        if (
            !isEmpty(appointmentData.title) && !isEmpty(appointmentData.text) && !isEmpty(appointmentData.amount)
            && !isEmpty(appointmentData.duration) && appointmentData.duration !== 0 && appointmentData.amount !== 0
        ) {
            storeSet.main.setAsyncRequest();
            const submitData = {
                'importoBreveTermine': appointmentData.amount,
                'durataMesiFinanziamento': appointmentData.duration,
                'nota': {
                    'titolo': appointmentData.title,
                    'testo': appointmentData.text
                }
            }

            AppointmentService.createAppointment(id, submitData, getAppointemntCallback, errGetAppointemntCallback);
        }
    }

    const getAppointemntCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (toast.current && data.message) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
        }
        setIsVisibleAppointmentDialog(false);
        storeSet.main.unsetAsyncRequest();
    }

    const errGetAppointemntCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: data.status === 'SUCCESS' ? 'info' : 'error',
                summary: '',
                detail: data.message
            });
        }
        setIsVisibleAppointmentDialog(false);
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const doMakeAdmisible = () => {
        // TODO
    }

    const evaluationBlockedForUser = (data = {}) => {
        const userData = storeGet.main.userData()
        return isAsyncRequest || userData.id !== data.assignedUserId;
    }
    
    const shouldDisableNewSoccorso = () => {
        if (data.evaluationVersion === 'V1') {
            return !allFilesRated || !atLeastOneChecked;
        } else if (data.evaluationVersion === 'V2') {
            return !allFilesRated || !atLeastOneChecked;
        } else {
            return true;
        }
    }

    const actionBtns = () => {
        return <div className="appPageSection__actions">
            {['EVALUATION', 'SOCCORSO', 'CLOSE'].includes(data.applicationStatus)
                ? <Button
                    type="button"
                    disabled={!data.id || data.status === 'CLOSE' 
                        || (data.applicationStatus === 'EVALUATION' && shouldDisableNewSoccorso()) 
                        || evaluationBlockedForUser(data)}
                    onClick={doNewSoccorso}
                    outlined
                    label={<>
                        {data.applicationStatus === 'EVALUATION'
                            ? __('Richiedi soccorso istruttorio', 'gepafin')
                            : __('Apri soccorso istruttorio', 'gepafin')}
                        <i style={{ marginLeft: 7 }}>
                            <HelpIcon/>
                        </i>
                    </>}
                /> : null}
            {data.id
                ? <Button
                    type="button"
                    disabled={data.status === 'CLOSE' || evaluationBlockedForUser(data)}
                    onClick={() => doSaveDraft()}
                    outlined
                    label={__('Salva bozza valutazione', 'gepafin')}
                    icon="pi pi-save" iconPos="right"/>
                : <Button
                    type="button"
                    onClick={() => doSaveDraft()}
                    label={__('Crea valutazione', 'gepafin')}
                    icon="pi pi-save" iconPos="right"/>}
            {APP_EVALUATION_FLOW_ID === '1' && ['EVALUATION'].includes(data.applicationStatus)
            && APP_HUB_ID !== 't7jh5wfg9QXylNaTZkPoE'
                ? <Button
                    type="button"
                    disabled={!data.id || !allFilesRated || !allChecksChecked
                        || !['EVALUATION'].includes(data.applicationStatus) || evaluationBlockedForUser(data)}
                    onClick={doCheckNDG}
                    label={__('Controlla NDG', 'gepafin')}
                /> : null}
            {APP_EVALUATION_FLOW_ID === '1' && APP_HUB_ID !== 't7jh5wfg9QXylNaTZkPoE'
                ? <Button
                    type="button"
                    disabled={!data.id || !['NDG'].includes(data.applicationStatus) || !data.ndg || evaluationBlockedForUser(data)}
                    onClick={doCreateAppointment}
                    label={__('Crea l\'appuntamento', 'gepafin')}
                /> : null}
            <Button
                type="button"
                disabled={!data.id || !['APPOINTMENT'].includes(data.applicationStatus) || evaluationBlockedForUser(data)}
                onClick={doMakeAdmisible}
                label={__('Ammissibile formalmente', 'gepafin')}
            />
            <Button
                type="button"
                disabled={true}
                onClick={() => {
                }}
                label={__('Valutazione tecnico-finanziaria positiva', 'gepafin')}
            />
            {data.id
                ? <Button
                    type="button"
                    disabled={!isAdmissible
                        || ['APPROVED','REJECTED'].includes(data.applicationStatus)
                        || evaluationBlockedForUser(data)
                        || (APP_EVALUATION_FLOW_ID === '1' && !['ADMISSIBLE'].includes(data.applicationStatus))
                    }
                    onClick={initiateApproving}
                    label={__('Domanda deliberata', 'gepafin')}
                    icon="pi pi-check" iconPos="right"/> : null}
            {data.id
                ? <Button
                    type="button"
                    disabled={['APPROVED','REJECTED'].includes(data.applicationStatus)
                        || APP_EVALUATION_FLOW_ID === '1'
                        && (!['EVALUATION', 'ADMISSIBLE', 'APPOINTMENT'].includes(data.applicationStatus)
                            || evaluationBlockedForUser(data))
                    }
                    onClick={initiateRejecting}
                    label={__('Respingi domanda', 'gepafin')}
                    icon="pi pi-times" iconPos="right"/> : null}
        </div>
    }

    useEffect(() => {
        let updatedFormValues = klona(formValues);
        let context = {};

        // eslint-disable-next-line array-callback-return
        formData.map((o) => {
            const variable = head(o.settings.filter(o => o.name === 'variable'));
            const formula = head(o.settings.filter(o => o.name === 'formula'));

            if (formula && !isEmpty(formula.value)) {
                context = getTokens(formula.value)
                    .filter(v => !['false', 'null', 'true'].includes(v))
                    .reduce((acc, cur) => {
                        acc[cur] = isNil(context[cur]) ? 0 : parseCommaDecimal(context[cur]);
                        return acc;
                    }, context);

                const mathFormula = renderWithDataVars(formula.value, context);
                try {
                    updatedFormValues[o.id] = evaluate(mathFormula);
                } catch (e) {
                    console.log('Error in math formula: "', mathFormula, '"', e.message);
                    updatedFormValues[o.id] = 0;
                }
            }

            if (variable && !isEmpty(variable.value)) {
                context[variable.value[0]] = 'criteria_table' === o.name
                    ? pathOr(0, [o.id, 'total'], updatedFormValues)
                    : pathOr(0, [o.id], updatedFormValues);
            }
        });

        if (!isEmpty(updatedFormValues) && !equal(updatedFormValues, formValues)) {
            reset(updatedFormValues);
        }

        updateFlagsForSoccorso(data);
    }, [formValues]);

    useEffect(() => {
        if (formInitialData) {
            //reset();
            Object.keys(formInitialData).map(k => setValue(k, formInitialData[k]));
            trigger();
        }
    }, [formInitialData]);

    useEffect(() => {
        const maxScore = pathOr(0, ['minScore'], data);
        const criteria = pathOr([], ['criteria'], data);
        const scoreSum = sum(criteria.map(o => o.score));

        setIsAdmissible(scoreSum !== 0 && scoreSum >= maxScore);
    }, [data]);

    useEffect(() => {
        const parsed = parseInt(id)
        const entityId = !isNaN(parsed) ? parsed : 0;

        storeSet.main.setAsyncRequest();
        ApplicationEvaluationService.getEvaluationVersionByApplId(entityId, getVersion, errGetVersion);
        AmendmentsService.getSoccorsoByApplId(entityId, getAmendmentsCallback, errGetAmendmentsCallback, [
            ['statuses', ['AWAITING', 'RESPONSE_RECEIVED']]
        ]);
    }, [id]);

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Valuta domanda', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>
            <Toast ref={toast}/>
            <BlockingOverlay shouldDisplay={isAsyncRequest}/>

            <div className="appPageSection__row">
                <Button
                    type="button"
                    outlined
                    onClick={goToEvaluationsPage}
                    label={__('Indietro', 'gepafin')}
                    icon="pi pi-arrow-left" iconPos="left"/>
            </div>

            <div className="appPage__spacer"></div>

            {!isAsyncRequest && !isEmpty(data)
                ? <div className="appPage__content">
                    <ApplicationInfo data={data}/>

                    <div className="appPageSection__hr">
                        <span>{__('Azioni rapide', 'gepafin')}</span>
                    </div>

                    <div className="appPageSection">
                        {actionBtns()}
                    </div>

                    <ApplicationDownloadFiles id={id}/>

                    <div className="appPageSection">
                        <h2>{__('Documenti aggiuntivi', 'gepafin')}</h2>
                        <RepeaterFields
                            defaultValue={data.evaluationDocument ?? []}
                            updateFn={(data) => updateEvaluationValue(
                                data,
                                ['evaluationDocument']
                            )}
                            shouldDisable={['APPROVED', 'REJECTED'].includes(data.applicationStatus) || evaluationBlockedForUser(data)}
                            sourceId={data.assignedApplicationId}
                            sourceName="evaluation"/>
                    </div>

                    {data.evaluationVersion === 'V2'
                        ? <div className="appPageSection">
                            <h2>{__('Documenti allegati', 'gepafin')}</h2>
                            {!isEmpty(data.files)
                                ? <ListOfFiles
                                    files={data.files}
                                    updateFn={updateEvaluationValue}
                                    shouldDisableFieldFn={(name) => shouldDisableField(name) || evaluationBlockedForUser(data)}
                                    name="files"
                                    ndg={data.ndg}
                                    applicationId={id}/>
                                : <p>{__('Nessun documento allegato', 'gepafin')}</p>}
                        </div>
                        : null}

                    {data.evaluationVersion === 'V2'
                        ? <form className="appForm" onSubmit={handleSubmit(() => {
                        })}>
                            {formData.map(o => {
                                const label = head(o.settings.filter(o => o.name === 'label'));
                                const text = head(o.settings.filter(o => o.name === 'text'));
                                const placeholder = head(o.settings.filter(o => o.name === 'placeholder'));
                                const options = head(o.settings.filter(o => o.name === 'options'));
                                let tableColumns = head(o.settings.filter(o => o.name === 'table_columns'));
                                if (!tableColumns) {
                                    tableColumns = head(o.settings.filter(o => o.name === 'criteria_table_columns'));
                                }
                                const step = head(o.settings.filter(o => o.name === 'step'));
                                const mime = head(o.settings.filter(o => o.name === 'mime'));
                                const formula = head(o.settings.filter(o => o.name === 'formula'));
                                let mimeValue = '';

                                if (mime) {
                                    mimeValue = mime.value.map(o => o.code ? o.code : o.ext);
                                }

                                const validations = Object.keys(o.validators).reduce((acc, cur) => {
                                    if (o.validators[cur]) {
                                        if (['min', 'max', 'minLength', 'maxLength', 'maxSize'].includes(cur)) {
                                            acc[cur] = parseInt(o.validators[cur]);
                                        } else if ('pattern' === cur) {
                                            acc[cur] = new RegExp(o.validators[cur]);
                                        } else if ('isRequired' === cur) {
                                            //acc[cur] = o.validators[cur];
                                            acc['required'] = true;
                                        } else if ('custom' === cur && validationFns[o.validators[cur]]) {
                                            if (!acc.validate) {
                                                acc.validate = {};
                                            }
                                            acc.validate[o.validators[cur]] = validationFns[o.validators[cur]];
                                        }
                                    }

                                    return acc;
                                }, {});

                                /*if (o.name === 'table') {
                                    console.log('value:', values[o.id] ? values[o.id] : '')
                                }*/

                                return ['paragraph'].includes(o.name) && text
                                    ? <div key={o.id}>
                                        <div className="ql-editor">
                                            {renderHtmlContent(text.value)}
                                        </div>
                                    </div>
                                    : <FormField
                                        key={o.id}
                                        readOnly={formula && !isEmpty(formula.value)}
                                        type={o.name}
                                        fieldName={o.id}
                                        label={label ? label.value : ''}
                                        placeholder={placeholder ? placeholder.value : ''}
                                        control={control}
                                        register={register}
                                        errors={errors}
                                        defaultValue={values[o.id] ? values[o.id] : ''}
                                        maxFractionDigits={step ? step.value : 0}
                                        accept={mimeValue}
                                        config={validations}
                                        options={options ? options.value : []}
                                        setDataFn={setValue}
                                        saveFormCallback={doSaveDraft}
                                        sourceId={id}
                                        useGrouping={false}
                                        tableColumns={tableColumns ? tableColumns.value : {}}
                                    />
                            })}
                        </form>
                        : null}

                    {data.evaluationVersion === 'V1'
                        ? <div className="appPageSection">
                            <h2>{__('Checklist Valutazione', 'gepafin')}</h2>
                            <div className="appPageSection columns">
                                <div>
                                    <h3>{__('Lista', 'gepafin')}</h3>
                                    <div className="appPageSection__withBorder grey" style={{ marginBottom: '20px' }}>
                                        <div className="appPageSection__checklist">
                                            {data.checklist.map((o, i) => <div key={o.id}>
                                                <Checkbox
                                                    disabled={shouldDisableField('checklist') || evaluationBlockedForUser(data)}
                                                    inputId={`checklist_${o.id}`}
                                                    onChange={(e) => updateEvaluationValue(
                                                        e.checked,
                                                        ['checklist', i, 'valid']
                                                    )}
                                                    checked={o.valid}></Checkbox>
                                                <label htmlFor={`checklist_${o.id}`}>{o.label}</label>
                                            </div>)}
                                        </div>
                                    </div>

                                    <h3>{__('Note', 'gepafin')}</h3>
                                    <div>
                                        <Editor
                                            value={data.note}
                                            readOnly={shouldDisableField('note') || evaluationBlockedForUser(data)}
                                            placeholder={__('Digita qui il messagio', 'gepafin')}
                                            headerTemplate={header}
                                            onTextChange={(e) => updateEvaluationValue(
                                                e.htmlValue,
                                                ['note']
                                            )}
                                            style={{ height: 80 * 3, width: '100%' }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h3>{__('Documenti allegati', 'gepafin')}</h3>
                                    {!isEmpty(data.files)
                                        ? <ListOfFiles
                                            files={data.files}
                                            updateFn={updateEvaluationValue}
                                            shouldDisableFieldFn={(name) => shouldDisableField(name) || evaluationBlockedForUser(data)}
                                            name="files"
                                            ndg={data.ndg}
                                            applicationId={id}/>
                                        : <p>{__('Nessun documento allegato', 'gepafin')}</p>}
                                </div>
                            </div>
                        </div>
                        : null}

                    {!isEmpty(data.amendmentDetails)
                        ? <div className="appPageSection">
                            <h2>{__('Documenti di soccorso', 'gepafin')}</h2>
                            <ListOfFiles
                                files={data.amendmentDetails}
                                updateFn={updateEvaluationValue}
                                shouldDisableFieldFn={(name) => shouldDisableField(name) || evaluationBlockedForUser(data)}
                                name="amendmentDetails"
                                ndg={data.ndg}
                                applicationId={id}/>
                        </div> : null}

                    {data.evaluationVersion === 'V1'
                        ? <div className="appPageSection">
                            <h2>{__('Punteggi di valutazione', 'gepafin')}</h2>
                            {data.criteria
                                ? <table className="myTable">
                                    <thead className="myThead">
                                    <tr>
                                        <th>{__('Parametro', 'gepafin')}</th>
                                        <th style={{ width: 200 }}>{__('Punteggio', 'gepafin')}</th>
                                        <th style={{ width: 220 }}>{__('Stato', 'gepafin')}</th>
                                    </tr>
                                    </thead>
                                    <tbody className="myTbody">
                                    {data.criteria.map((o, i) => <tr key={o.id}>
                                        <td>{o.label}</td>
                                        <td>
                                            <div className="p-inputgroup">
                                                <InputNumber
                                                    disabled={shouldDisableField('criteria') || evaluationBlockedForUser(data)}
                                                    placeholder={__('Punteggio', 'gepafin')}
                                                    keyfilter="int"
                                                    value={o.score}
                                                    max={o.maxScore}
                                                    onChange={(e) => updateEvaluationValue(
                                                        e.value,
                                                        ['criteria', i, 'score'],
                                                        o.criteria
                                                    )}/>
                                                <span className="p-inputgroup-addon">
                                                / {o.maxScore}
                                            </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="appPageSection__iconActions">
                                                {!isEmpty(o.criteriaMappedFields)
                                                    ? <Button icon="pi pi-eye"
                                                              rounded outlined severity="info"
                                                              onClick={() => displayCriterionData(o.id)}
                                                              aria-label={__('Mostra', 'gepafin')}/> : null}
                                                <Button icon="pi pi-thumbs-up" rounded outlined
                                                        disabled={shouldDisableField('criteria') || evaluationBlockedForUser(data)}
                                                        severity={!isNil(o.valid) && o.valid ? 'success' : 'secondary'}
                                                        onClick={() => updateEvaluationValue(
                                                            true,
                                                            ['criteria', i, 'valid']
                                                        )}
                                                        aria-label={__('Su', 'gepafin')}/>
                                                <Button icon="pi pi-thumbs-down" rounded outlined
                                                        disabled={shouldDisableField('criteria') || evaluationBlockedForUser(data)}
                                                        severity={!isNil(o.valid) && !o.valid ? 'danger' : 'secondary'}
                                                        onClick={() => updateEvaluationValue(
                                                            false,
                                                            ['criteria', i, 'valid']
                                                        )}
                                                        aria-label={__('Giu', 'gepafin')}/>
                                            </div>
                                        </td>
                                    </tr>)}
                                    <tr>
                                        <td>{__('Punteggio:', 'gepafin')}</td>
                                        <td>{sum(data.criteria.map(o => o.score))}</td>
                                        <td>
                                            {isAdmissible
                                                ? <Tag icon="pi pi-check" severity="success"
                                                       value={__('Punteggio sufficiente per passaggio alla valutazione tecnica ed economico finanziaria')}></Tag> : null}
                                            {!isAdmissible
                                                ? <Tag icon="pi pi-times" severity="warning"
                                                       value={__('Punteggio non sufficiente per passaggio alla valutazione tecnica ed economico finanziaria')}></Tag> : null}
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot className="myTfoot">
                                    <tr>
                                        <td colSpan="3">{sprintf(__('Punteggio minimo per l\'ammissione: %d'), data.minScore)}</td>
                                    </tr>
                                    </tfoot>
                                </table> : null}
                        </div>
                        : null}

                    <div className="appPage__spacer"></div>

                    <div className="appPageSection__hr">
                        <span>{__('Azioni rapide', 'gepafin')}</span>
                    </div>

                    <div className="appPageSection">
                        {actionBtns()}
                    </div>

                    <Dialog
                        header={criterionDataTitle}
                        visible={isVisibleCriterionData !== 0}
                        style={{ width: '50vw' }}
                        onHide={hideCriterionData}>
                        {criterionDataContent}
                    </Dialog>

                    <Dialog
                        visible={isVisibleCompleteDialog}
                        modal
                        header={headerCompleteDialog}
                        footer={footerCompleteDialog}
                        style={{ maxWidth: '600px', width: '100%' }}
                        onHide={hideCompleteDialog}>
                        <div className="appForm__field">
                            <label
                                className={classNames({ 'p-error': isEmpty(amountAccepted) || amountAccepted === 0 })}>
                                {__('Importo approvato', 'gepafin')}
                            </label>
                            <InputNumber
                                value={amountAccepted}
                                keyfilter="int"
                                invalid={isEmpty(amountAccepted) || amountAccepted === 0}
                                onChange={(e) => setAmountAccepted(e.value)}/>
                        </div>
                        <div className="appForm__field">
                            <label>{__('Motivazione', 'gepafin')}</label>
                            <Editor
                                value={motivation}
                                readOnly={loading}
                                placeholder={__('Digita qui il messagio', 'gepafin')}
                                headerTemplate={header}
                                onTextChange={(e) => setMotivation(e.htmlValue)}
                                style={{ height: 80 * 3, width: '100%' }}
                            />
                        </div>
                    </Dialog>

                    <Dialog
                        visible={isVisibleAppointmentDialog}
                        modal
                        header={headerAppointmentDialog}
                        footer={footerAppointmentDialog}
                        style={{ maxWidth: '600px', width: '100%' }}
                        onHide={hideAppointmentDialog}>
                        <div className="appForm__field">
                            <label
                                className={classNames({ 'p-error': isEmpty(appointmentData.amount) || appointmentData.amount === 0 })}>
                                {__('Importo', 'gepafin')}
                            </label>
                            <InputNumber
                                value={appointmentData.amount}
                                keyfilter="int"
                                invalid={isEmpty(appointmentData.amount) || appointmentData.amount === 0}
                                onChange={(e) => setFieldValue('amount', e.value)}/>
                        </div>
                        <div className="appForm__field">
                            <label
                                className={classNames({ 'p-error': isEmpty(appointmentData.duration) || appointmentData.duration === 0 })}>
                                {__('Durata', 'gepafin')}
                            </label>
                            <InputNumber
                                value={appointmentData.duration}
                                keyfilter="int"
                                invalid={isEmpty(appointmentData.duration) || appointmentData.duration === 0}
                                onChange={(e) => setFieldValue('duration', e.value)}/>
                        </div>
                        <div className="appForm__field">
                            <label className={classNames({ 'p-error': isEmpty(appointmentData.title) })}>
                                {__('Titolo', 'gepafin')}
                            </label>
                            <InputText
                                value={appointmentData.title}
                                invalid={isEmpty(appointmentData.title)}
                                onChange={(e) => setFieldValue('title', e.target.value)}/>
                        </div>
                        <div className="appForm__field">
                            <label className={classNames({ 'p-error': isEmpty(appointmentData.text) })}>
                                {__('Messaggio', 'gepafin')}
                            </label>
                            <InputTextarea
                                value={appointmentData.text}
                                invalid={isEmpty(appointmentData.text)}
                                onChange={(e) => setFieldValue('text', e.target.value)}
                                rows={3}
                                cols={30}/>
                        </div>
                    </Dialog>

                </div>
                : <>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem"></Skeleton>
                </>}
        </div>
    )

}

export default DomandaEditPreInstructor;
