import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { is, isEmpty, isNil } from 'ramda';
import { klona } from 'klona';
import { TZDate } from '@date-fns/tz';

// components
import FormField from '../../../../components/FormField';
import FormFieldRepeater from '../../../../components/FormFieldRepeater';
import FormFieldRepeaterCriteria from '../../../../components/FormFieldRepeaterCriteria';
import BandoEditFormActions from '../BandoEditFormActions';
import UnsavedChangesDetector from '../../../../components/UnsavedChangesDetector';
import { Toast } from 'primereact/toast';

// api
import BandoService from '../../../../service/bando-service';
import LookupdataService from '../../../../service/lookupdata-service';

// store
import { storeSet } from '../../../../store';
import getTimeParsedFromString from '../../../../helpers/getTimeParsedFromString';
import { mimeTypes } from '../../../../configData';
import { wrap } from 'object-path-immutable';
import formatDateString from '../../../../helpers/formatDateString';

const BandoEditFormStep2 = forwardRef(function ({ initialData, setInitialData, getFormErrors, status }, ref) {
    const navigate = useNavigate();
    const [criteriaOptions, setCriteriaOptions] = useState([]);
    const [checklistOptions, setChecklistOptions] = useState([]);
    const [formInitialData, setFormInitialData] = useState(initialData);
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        register,
        trigger,
        getValues,
        clearErrors
    } = useForm({
        defaultValues: useMemo(() => {
            return formInitialData;
        }, [formInitialData]), mode: 'onChange'
    });
    const values = getValues();
    //const step2Props = ['threshold', 'criteria', 'checkList', 'docs', 'images'];
    const toast = useRef(null);

    const onSubmit = () => {};

    const onSaveDraft = () => {
        trigger();
        let formData = klona(getValues());
        formData.dates = [];

        if (formData.startDate) {
            let starDate;

            if (is(String, formData.startDate)) {
                starDate = formData.startDate;
            } else {
                starDate = formatDateString(formData.startDate);
            }

            formData = wrap(formData).insert(['dates'], starDate, 0).value();
        }
        if (formData.endDate) {
            let endDate;

            if (is(String, formData.endDate)) {
                endDate = formData.endDate;
            } else {
                endDate = formatDateString(formData.endDate);
            }

            formData = wrap(formData).insert(['dates'], endDate, 1).value();
        }
        if (!isNil(formData.startTime)) {
            if (!is(String, formData.startTime)) {
                const tzAwareDate = new TZDate(formData.startTime, 'Europe/Berlin');
                formData.startTime = tzAwareDate.toISOString().substring(11, 16);
            }
        }
        if (!isNil(formData.endTime)) {
            if (!is(String, formData.endTime)) {
                const tzAwareDate = new TZDate(formData.endTime, 'Europe/Berlin');
                formData.endTime = tzAwareDate.toISOString().substring(11, 16);
            }
        }

        delete formData.startDate;
        delete formData.endDate;

        storeSet.main.setAsyncRequest();
        if (values.evaluationVersion === 'V1') {
            BandoService.updateBandoStep2(formData.id, formData, createCallback, errCreateCallback);
        } else if (values.evaluationVersion === 'V2') {
            BandoService.updateBandoStep2V2(formData.id, formData, createCallback, errCreateCallback);
        }
    }

    const createCallback = (data) => {
        storeSet.main.unsetAsyncRequest();
        if (data.status === 'SUCCESS') {
            toast.current.show({
                severity: 'success',
                summary: '',
                detail: __('Il bando è stato aggiornato correttamente!', 'gepafin')
            });
            const newFormData = {...formInitialData, ...data.data};
            setFormInitialData(newFormData);
            setInitialData(newFormData);
        }
    }

    const errCreateCallback = (data) => {
        storeSet.main.unsetAsyncRequest();
        console.log('errCreateCallback', data);
    }

    const lookupdataCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const criteria = data.data
                .filter(o => o.type === 'EVALUATION_CRITERIA')
                .map(o => {
                    delete o.type;
                    return {
                        ...o,
                        score: 0,
                        lookUpDataId: o.id,
                        id: null
                    };
                });
            setCriteriaOptions(criteria);
            const checklist = data.data
                .filter(o => o.type === 'CHECKLIST')
                .map(o => {
                    delete o.type;
                    return {
                        ...o,
                        lookUpDataId: o.id,
                        id: null
                    };
                });
            setChecklistOptions(checklist);
        }
    }

    const errLookupdataCallback = (data) => {
        console.log('errLookupdataCallback', data)
    }

    const openPreview = () => {
        navigate(`/bandi/${values.id}/preview`);
    }

    const openPreviewEvaluation = () => {
        navigate(`/bandi/${values.id}/preview-evaluation`);
    }

    useImperativeHandle(
        ref,
        () => {
            return {
                isFormValid: () => {
                    return isValid;
                },
                getErrors: () => {
                    return errors;
                },
                getValues: () => {
                    return getValues();
                }
            }
        }, [errors, isValid]);

    useEffect(() => {
        storeSet.main.formInitialData(initialData);
        setFormInitialData(initialData);
    }, [initialData]);

    useEffect(() => {
        const newFormData = klona(formInitialData);
        if (!isNil(formInitialData.dates) && formInitialData.dates.length) {
            newFormData.dates = formInitialData.dates.map(v => is(String, v) ? new Date(v) : (v ? v : ''));
        }
        if (!isNil(formInitialData.startTime) && !isEmpty(formInitialData.startTime)) {
            newFormData.startTime = is(String, formInitialData.startTime)
                ? getTimeParsedFromString(formInitialData.startTime)
                : formInitialData.startTime;
        }
        if (!isNil(formInitialData.endTime) && !isEmpty(formInitialData.endTime)) {
            newFormData.endTime = is(String, formInitialData.endTime)
                ? getTimeParsedFromString(formInitialData.endTime)
                : formInitialData.endTime;
        }

        Object.keys(newFormData).map(v => setValue(v, newFormData[v]));
    }, [formInitialData]);

    useEffect(() => {
        trigger().then(() => clearErrors());
        LookupdataService.getItems(lookupdataCallback, errLookupdataCallback, [['type', ['CHECKLIST', 'EVALUATION_CRITERIA']]]);

        return () => {
            storeSet.main.formInitialData({});
        }
    }, []);

    const shouldDisableField = (fieldName) => {
        return values.status === 'PUBLISH' &&
            ![
                'descriptionShort', 'descriptionLong', 'documentationRequested', 'threshold',
                'aimedTo', 'criteria', 'docs', 'checklist', 'faq', 'amount', 'amountMin', 'amountMax',
                'email', 'phoneNumber', 'checkList', 'images'
            ].includes(fieldName)
    }

    const acceptAllFormats = () => {
        return mimeTypes.map(o => o.code);
    }

    return (
        <form className="appForm" onSubmit={handleSubmit(onSubmit)}>
            <UnsavedChangesDetector getValuesFn={getValues}/>
            {values.evaluationVersion === 'V1'
                ? <FormFieldRepeaterCriteria
                data={values}
                disabled={shouldDisableField('criteria')}
                setDataFn={setValue}
                fieldName="criteria"
                options={criteriaOptions}
                errors={errors}
                register={register}
                label={<>{__('Criteri di valutazione', 'gepafin')}*
                    <span>{__('(almeno 1 criterio di valutazione)', 'gepafin')}</span></>}
                config={{
                    validate: {
                        minOneItem: v => !isEmpty(v) || __('Almeno 1 criterio di valutazione', 'gepafin'),
                        noEmptyValue: v => v
                                .filter(o => isEmpty(o.value) || isEmpty(o.score)).length === 0
                            || __('Non lasciare il valore vuoto', 'gepafin')
                    }
                }}/> : null}

            <FormField
                type="fileuploadasync"
                disabled={shouldDisableField('docs')}
                setDataFn={setValue}
                fieldName="docs"
                label={__('Documentazione', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['docs']}
                config={{ required: __('È obbligatorio', 'gepafin') }}
                accept={acceptAllFormats()}
                chooseLabel={__('Aggiungi documento', 'gepafin')}
                multiple={true}
                doctype='document'
                register={register}
                sourceId={values.id}
                source="call"
            />

            <FormField
                type="fileuploadasync"
                disabled={shouldDisableField('images')}
                setDataFn={setValue}
                fieldName="images"
                label={__('Immagine del Bando', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['images']}
                doctype='images'
                register={register}
                sourceId={values.id}
                source="call"
                multiple={false}
            />

            {values.evaluationVersion === 'V1'
                ? <FormFieldRepeater
                data={values['checkList']}
                disabled={shouldDisableField('checkList')}
                setDataFn={setValue}
                fieldName="checkList"
                options={checklistOptions}
                errors={errors}
                register={register}
                label={<>{__('Checklist valutazione Istruttoria', 'gepafin')}*
                    <span>{__('(almeno 1 elemento)', 'gepafin')}</span></>}
                config={{
                    validate: {
                        minOneItem: v => !isEmpty(v) || __('Almeno 1 elemento', 'gepafin'),
                        noEmptyValue: v => v
                            .filter(o => isEmpty(o.value)).length === 0 || __('Non lasciare il valore vuoto', 'gepafin')
                    }
                }}
            /> : null}

            <div className="appPage__spacer"></div>

            <div className="appPageSection__hr">
                <span>{__('Azioni', 'gepafin')}</span>
            </div>

            <Toast ref={toast} />
            <BandoEditFormActions
                id={values.id}
                status={status}
                submitFn={onSaveDraft}
                openPreview={openPreview}
                openPreviewEvaluation={openPreviewEvaluation}/>
        </form>
    )
})

export default BandoEditFormStep2;